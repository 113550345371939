import type { QueryParameters } from "~/composables/useQueryParameters";

export function useLocale() {
  const { locale } = useNuxtApp().$i18n;

  async function handleQueryParameters(queryParameters: QueryParameters) {
    const { setLocale, locales } = useNuxtApp().$i18n;

    if (queryParameters.has("lang")) {
      const lowerCaseLangParameter = queryParameters.getAndRemove("lang").toLocaleLowerCase();
      const locale = locales.value.find((l) => l.code === lowerCaseLangParameter);

      await setLocale(locale?.code ?? "en");
    }
  }

  /*
   * Formats a number to a string with the current locale
   * @param num - the number to format
   */
  function getNumberFormatted(num: number, options: Intl.NumberFormatOptions = {}): string {
    return new Intl.NumberFormat(locale.value, {
      style: "decimal",
      ...options,
    }).format(num);
  }

  return {
    handleQueryParameters,
    getNumberFormatted,
  };
}
