export default defineNuxtRouteMiddleware(async () => {
  const { $pinia } = useNuxtApp();
  const voucherStore = useVoucherStore($pinia);
  const userStore = useUserStore($pinia);

  if (voucherStore.temporaryVoucherCode && userStore.user) {
    await voucherStore.redeemCouponCode(voucherStore.temporaryVoucherCode);
    voucherStore.temporaryVoucherCode = undefined;
    voucherStore.$persist();

    return navigateTo("/onboarding/success");
  }
});
