import type { Ref } from "vue";
import { z } from "zod";
import { useBackendApiContentDomainConfig } from "~/composables/api/useBackendApiContentDomainConfig";

export const PartnerContentSchema = z.object({
  image_url: z.string().optional(),
  name: z.string(),
  tips: z.array(z.string()).optional().default([]),
  quote: z.string().optional().default(""),
  plural: z.boolean().default(false),
});

export type PartnerContent = z.infer<typeof PartnerContentSchema>;

export function useBackendApiContentDomainClient() {
  const { baseUrl } = useBackendApiContentDomainConfig();
  const { nuxtFetch } = useRequest(baseUrl);

  function getPartnerContentByDiscountCode(discountCode: Ref<string>) {
    return nuxtFetch<PartnerContent>(() => `/content/v1/partner-content/${discountCode.value}`, {
      immediate: false,
      transform: (res) => PartnerContentSchema.parse(res),
    });
  }

  return {
    getPartnerContentByDiscountCode,
  };
}
