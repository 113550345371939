import type { AbTests } from "~/types/abTest";

export const testConfig = {
  loading_screen_app: {
    variants: [
      {
        variant: "loading_screen_app_baseline",
        percentage: 50,
      },
      {
        variant: "loading_screen_app_variant",
        percentage: 50,
      },
    ],
  },
  remove_sepa: {
    variants: [
      {
        variant: "remove_sepa_baseline",
        percentage: 50,
      },
      {
        variant: "remove_sepa_variant",
        percentage: 50,
      },
    ],
  },
} as const satisfies AbTests;

const abTestEnabledStatus: Record<keyof typeof testConfig, boolean> = {
  loading_screen_app: true,
  remove_sepa: true,
};

export const enabledAbTests = Object.keys(abTestEnabledStatus).filter(
  (key) => abTestEnabledStatus[key as keyof typeof abTestEnabledStatus],
);
