import type {
  ActionEvent,
  ActionEventParams,
  BiTrackingEvents,
  BiTrackingHeader,
  BiTrackingRequestBody,
  BiTrackingResponseData,
  EventMetadataProperties,
  EventParams,
  EventProperties,
  ImpressionEvent,
  ImpressionEventParams,
  PurchaseEvent,
  PurchaseEventParams,
} from "~/types/biTracking";
import { BiTrackingEventType } from "~/types/biTracking";

export function useBiTrackingClient(apiUrl: string = useBiTrackingApiUrlProvider()) {
  const { $pinia } = useNuxtApp();
  const biTrackingStore = useBiTrackingStore($pinia);
  const onboardingStore = useOnboardingStore($pinia);

  const { heyflowId, header, metadata } = storeToRefs(biTrackingStore);
  const { skippedOnboarding } = storeToRefs(onboardingStore);
  const { updateHeaders, getSessionId } = biTrackingStore;

  const { fetch } = useRequest();

  function trackAction(params: ActionEventParams, headerParams: Partial<BiTrackingHeader> = {}) {
    const event = makeActionEvent(params);
    return trackEvents([event], headerParams);
  }

  function trackImpression(params: ImpressionEventParams, headerParams: Partial<BiTrackingHeader> = {}) {
    const event = makeImpressionEvent(params);
    return trackEvents([event], headerParams);
  }

  function trackPurchase(params: PurchaseEventParams, headerParams: Partial<BiTrackingHeader> = {}) {
    const event = makePurchaseEvent(params);
    return trackEvents([event], headerParams);
  }

  function trackEvents(events: BiTrackingEvents, headerParams: Partial<BiTrackingHeader> = {}) {
    updateHeaders(headerParams);

    const body: BiTrackingRequestBody = [
      {
        header: header.value,
        events,
      },
    ];
    return sendRequest(apiUrl, body);
  }

  function makeEventSharedParams(params: EventParams) {
    return {
      sessionId: getSessionId(),
      date: {
        isoDateTime: new Date().toISOString(),
      },
      properties: parseProperties((params.properties || metadata.value) as EventProperties),
    };
  }

  function makeActionEvent(params: ActionEventParams): ActionEvent {
    return {
      ...makeEventSharedParams(params),
      type: BiTrackingEventType.ACTION,
      name: params.name + ".answer",
      properties: makeActionEventProperties(params),
    };
  }

  function makeActionEventProperties(params: ActionEventParams): string {
    return JSON.stringify({
      ...extractEventMetadataProperties((params.properties || metadata.value) as EventProperties),
      selection: params.selection,
      screen_type: params.screenType,
    });
  }

  function makeImpressionEvent(params: ImpressionEventParams): ImpressionEvent {
    return {
      ...makeEventSharedParams(params),
      type: BiTrackingEventType.IMPRESSION,
      name: params.name ?? "",
    };
  }

  function makePurchaseEvent(params: PurchaseEventParams): PurchaseEvent {
    return {
      ...makeEventSharedParams(params),
      type: BiTrackingEventType.PURCHASE,
      duration: {
        isoPeriod: params.period,
      },
      sku: params.sku,
      currency: params.currency,
      gateway: params.gateway,
      amountCustomerFacingCurrency: params.amountCustomerFacingCurrency,
    };
  }

  function parseProperties(properties: EventProperties = {}): string {
    return JSON.stringify(extractEventMetadataProperties(properties));
  }

  function extractEventMetadataProperties(properties: EventProperties = {}): EventMetadataProperties {
    return {
      mediasource: properties.utm_medium ?? "",
      channel: properties.utm_source ?? "",
      campaignid: properties.utm_campaign ?? "",
      adsetid: properties.utm_term ?? "",
      adid: properties.utm_content ?? "",
      heyflow_id: heyflowId.value ?? "",
      skipped_onboarding: skippedOnboarding.value,
    };
  }

  function sendRequest(url: string, body: BiTrackingRequestBody): Promise<BiTrackingResponseData> {
    return fetch<BiTrackingResponseData>(url, {
      method: "post",
      headers: {
        "Content-Type": "application/vnd.yazio.events+json",
      },
      body: JSON.stringify(body),
    });
  }

  return {
    trackImpression,
    trackPurchase,
    trackAction,
  };
}
