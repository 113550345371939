import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45handle_45query_45parameters_45global from "/app/middleware/01.handleQueryParameters.global.ts";
import _02_45redirect_45from_45root_45global from "/app/middleware/02.redirectFromRoot.global.ts";
import _03_45set_45day_45js_45locale_45global from "/app/middleware/03.setDayJSLocale.global.ts";
import _04_45import_45web_45fonts_45global from "/app/middleware/04.importWebFonts.global.ts";
import _05_45load_45initial_45data_45global from "/app/middleware/05.loadInitialData.global.ts";
import _06_45set_45ab_45test_45group_45global from "/app/middleware/06.setAbTestGroup.global.ts";
import _07_45handle_45free_45voucher_45global from "/app/middleware/07.handleFreeVoucher.global.ts";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45handle_45query_45parameters_45global,
  _02_45redirect_45from_45root_45global,
  _03_45set_45day_45js_45locale_45global,
  _04_45import_45web_45fonts_45global,
  _05_45load_45initial_45data_45global,
  _06_45set_45ab_45test_45group_45global,
  _07_45handle_45free_45voucher_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "awin-basket-freeze": () => import("/app/middleware/awinBasketFreeze.ts"),
  "can-access-finalize-account-page": () => import("/app/middleware/canAccessFinalizeAccountPage.ts"),
  "dummy-query-params-redirect": () => import("/app/middleware/dummyQueryParamsRedirect.ts"),
  "handle-already-pro-users": () => import("/app/middleware/handleAlreadyProUsers.ts"),
  "handle-checkout-result": () => import("/app/middleware/handleCheckoutResult.ts"),
  "hide-in-production": () => import("/app/middleware/hideInProduction.ts"),
  "load-subscription-plans": () => import("/app/middleware/loadSubscriptionPlans.ts"),
  "redirect-authenticated-users": () => import("/app/middleware/redirectAuthenticatedUsers.ts"),
  "redirect-unauthenticated-to-login": () => import("/app/middleware/redirectUnauthenticatedToLogin.ts"),
  "validate-registration-params": () => import("/app/middleware/validateRegistrationParams.ts")
}